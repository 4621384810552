const fields = [
    { key: 'index', label: "No", _style:'min-width:50px'},
    { key: 'select', label: "select", _style:'min-width:50px' },
    { key: 'nama_kelompok', label: "Nama Kelompok", _style:'min-width:150px;' },
    { key: 'jenis_bantuan', label: "Jenis Bantuan", _style:'min-width:150px;' },
    { key: 'jumlah', label: "Jumlah", _style:'min-width:150px;' },
    { key: 'tahun', label: "Tahun", _style:'min-width:150px;' },
    { key: 'sumber_dana', label: "Sumber Dana", _style:'min-width:150px;' },
    { key: 'keterangan', label: "Keterangan", _style:'min-width:150px;' },
    { key: 'dibuat_oleh', label:"Dibuat oleh",  _style:'min-width:150px;'},
    { key: 'tanggal_dibuat', label: "Tanggal Dibuat", _style:'min-width:150px;' },
    { key: 'tanggal_dirubah', label: "Tanggal Dirubah", _style:'min-width:150px;' },
    { key: 'file_bast', label: "Bast", _style:'min-width:150px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:150px'}
]


export { fields }